import React, { Fragment, useCallback } from 'react'
import Link from '../../../../components/atoms/Link';
import Typography from '../../../../components/atoms/Typography';
import Box from '../../../../components/molecules/Box';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Picture from '../../../../components/atoms/Picture';
import useStyles from './style';
import { isEmpty } from 'lodash';
import TagsWrapper from '../../../../components/molecules/CarouselCard/TagsWrapper';
import aspectRatios from '../../../../utils/AspectRatios';
import ellipsisText from '../../../../utils/ellipsisText';

const MultiFilterCard = (props) => {
  const { sliderItem = [] } = props
  const classes = useStyles(props);
  const ConditionalWrapper = ({ condition, wrapper, children, props }) => {
    let href = props?.imageCtaUrl?.value?.href || props?.imageCtaUrl?.value || props?.imageCtaUrl || null;
    return (
      <Fragment>
        {' '}
        {condition ? (
          wrapper(children)
        ) : (
          <Link
            href={href}
            title={props.title && props.title.value || ''}
            isCustom={true}
            className={props.classes.root}
          >
            {children}
          </Link>
        )}
      </Fragment>
    )
  };

  const renderImageWrapper = (items) => {
    const { name, nameCF, itemUrl = {}, appThumbnailImage, thumbnailImage, mobileImage, isParalax = false, interestType, id } = items
    const title = nameCF || name
    const truncateName = ellipsisText(title, 40)
    return (
      <ConditionalWrapper
        condition={isEmpty(itemUrl)}
        // condition={true}
        props={{
          title: title,
          classes,
          imageCtaUrl: itemUrl,
        }}
        wrapper={children => (
          <div className={classes.listItemContainer}>
            {children}
          </div>
        )}
      >
        <Box className={'multiFilteredCardImageWrapper multiFilterWrapper'} >
          {
            interestType &&
            <Box className='multi-filter-content'>
              <TagsWrapper
                ctaLink={interestType.filter(item => item !== '')}
                itemUniqueKey={id}
                className={'filter-category-tag'}
                customWidth={35}
              />
              <Typography variant="h5Bold" title={title} component="h2" className={`pillarTitle ${classes.pillarTitle}`} >
                <Text field={{ value: truncateName }} />
              </Typography>
            </Box>
          }
          <Picture
            media={{
              image: { value: thumbnailImage ?? {} },
              // mobileImage: image,
              aspectRatio: aspectRatios['3x2-card'],
            }}
            isParalax={isParalax}
          />
        </Box>
      </ConditionalWrapper >
    )
  }


  const selectImageForMultiFilterCard = useCallback((variant, item) => {
    switch (variant) {
      case 'single':
        return (
          <>
            {
              item[0] && (renderImageWrapper(item[0]))
            }
          </>
        )

      case 'double':
        return (
          <>
            <Box className='doubleImage'>
              {
                item[0] && (
                  <Box>
                    {renderImageWrapper(item[0])}
                  </Box>
                )}
              {
                item[1] && (
                  <Box>
                    {renderImageWrapper(item[1])}
                  </Box>
                )}
            </Box>
          </>
        )

      default:
        return <></>
    }
  }, [sliderItem])
  const selectVariant = sliderItem?.length === 2 ? 'double' : 'single'

  return (
    <>
      {selectImageForMultiFilterCard(selectVariant, sliderItem, classes)}
    </>
  )

}

export default MultiFilterCard