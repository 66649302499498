import { createSelector } from 'reselect';

const getEventListData = state => {
  return state;
};

export const selectEventListData = createSelector(
  getEventListData,
  data => data.EventListReducer.data
);
