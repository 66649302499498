import React from 'react';
import Button from '../../atoms/Button';
import DctSvgIcon from '../../atoms/Svg';
import PropTypes from 'prop-types';
import useStyles from './CustomArrows.style';
import clsx from 'clsx';
export function PrevArrow(props) {
  const { className, style, onClick, styleVariant, colorVariant } = props;
  const classes = useStyles();
  const isDisabled = className.includes('slick-disabled');
  return (
    <div
      className={clsx(
        `${styleVariant !== undefined && classes[styleVariant]}`,
        `${colorVariant !== '' && classes[colorVariant]}`,
        classes.root,
        classes.prevArrow,
        className,
      )}
      style={{ ...style }}
      onClick={onClick}
    >
      <Button
        size="medium"
        iconOnly={true}
        iconType="outlined"
        srText="Previous"
        disableRipple={true}
        disabled={isDisabled}
      >
        <DctSvgIcon name="ArrowBackIosSharp" />
      </Button>
    </div>
  );
}

export function NextArrow(props) {
  const { className, style, onClick, styleVariant, colorVariant } = props;
  const classes = useStyles();
  const isDisabled = className.includes('slick-disabled');
  return (
    <div
      className={clsx(
        `${styleVariant !== '' && classes[styleVariant]}`,
        `${colorVariant !== '' && classes[colorVariant]}`,
        classes.root,
        classes.nextArrow,
        className,
      )}
      style={{ ...style }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Button
        size="medium"
        iconOnly={true}
        iconType="outlined"
        srText="Next"
        disableRipple={true}
        disabled={isDisabled}
      >
        <DctSvgIcon name="ArrowForwardIosSharp" />
      </Button>
    </div>
  );
}

const proptTypesObj = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  styleVariant: PropTypes.string,
  colorVariant: PropTypes.string,
}

// Expected value for styleVariant
// .circleVariant

// Expected value for colorVariant
// .whiteVariant

PrevArrow.propTypes = proptTypesObj;

NextArrow.propTypes = proptTypesObj;
