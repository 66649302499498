import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import SharedMultiCardContent from '../../../components/organism/SharedMultiCardcontent/SharedMultiCardContent';
import { getProcessedProps } from './preProcessData';
import { getCurrentLanguage } from '../../../utils/getCurrentLanguage';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { getPageName } from '../../../utils/getPageComponentInfo';
import { COMPONENT_NAME } from './locators';
import { NoSsr } from '@material-ui/core';
import useStyles from './style';
import Picture from '../../../components/atoms/Picture';
import Box from '../../../components/molecules/Box';
import { getUId } from '../../../../utils/utility';

const EventCarousel = props => {
  const classes = useStyles(props);
  console.log('props.fields', props.fields?.arrowColor?.fields?.colorCode?.value)
  const uid = getUId(props)
  const { fields, getEventListStart, eventListData, sitecoreContext } = props;
  const featuredText = props && props.t && props.t('featuredText');
  const [hasFavouriteBasket, setHasFavouriteBasket] = useState(false);
  let isEditor;

  const previewMode =
    sitecoreContext &&
    sitecoreContext.pageState &&
    sitecoreContext.pageState.toLowerCase();

  if (isExperienceEditorActive() || previewMode === 'preview') {
    isEditor = true;
  } else {
    isEditor = false;
  }
  const placeholders = get(sitecoreContext, 'VADStaticRoute.sitecore.route.placeholders', '');
  const vadHeader = get(placeholders, 'vad-header', []);

  useEffect(() => {
    const favouriteBasket = vadHeader.find(item => item.componentName === 'FavoriteBasket');
    const favIsActive = get(favouriteBasket, 'fields["Is Active"].value', false);
    setHasFavouriteBasket(Boolean(favouriteBasket) && favIsActive)
  }, [vadHeader])

  const language = getCurrentLanguage(props);
  useEffect(() => {
    if (language) {
      getEventListStart(language, isEditor);
    }
  }, [language]);

  const {
    title,
    ctaButton,
    segmentTitle,
    logoImage,
    logoLink,
    desktopImage,
    mobileImage,
    supergraphicImage,
    supergraphicImageMobile,
  } = fields;
  const expectedProps = getProcessedProps(eventListData, featuredText);
  expectedProps.fields = {
    ...expectedProps.fields,
    title,
    description: { value: get(ctaButton, 'value.text') },
    cta: ctaButton,
    segmentName: segmentTitle,
    logoImage,
    logoLink,
    desktopImage,
    mobileImage,
    language,
    pageName: getPageName(props),
    componentName: COMPONENT_NAME,
  };


  return (
    <div data-locator={COMPONENT_NAME} uid={uid} className={classes.eventCarouselMain}>
      <NoSsr>
        <Box className={'eventBgImage'}>
          <Picture
            media={{
              image: supergraphicImage,
              mobileImage: supergraphicImageMobile,
              disableLazyLoad: true,
              fallbackBackgroundNeeded: false,
              aspectRatio: { mobile: '5x4', desktop: '16x8' },
            }}
            isParalax={false}

          />
        </Box>
        <SharedMultiCardContent {...expectedProps} hasFavouriteBasket={hasFavouriteBasket} />
      </NoSsr>
    </div>
  );
};

export default EventCarousel;
