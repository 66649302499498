import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
  eventCarouselMain: {
    position: 'relative',
    overflow: 'hidden',
    '& .isEventCarousel': {
      marginBottom: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
      background: (props) => !isEmpty(props.fields?.backgroundColor?.fields?.colorCode?.value) ? props.fields?.backgroundColor?.fields?.colorCode?.value : theme.palette.common.DarkTurquoise,
      '& .collage-description': {
        [theme.breakpoints.down('md')]: {
          paddingBottom: pxToRem(48),
        },
      },
      '& .collage-description, & a': {
        color: '#FFFF',
        '& .sec-heading': {
          marginBottom: pxToRem(24),
          display: 'flex',
        },
        '& h2': {
          fontWeight: 700,
          ...theme.palette.common.ellipsis(1),

          [theme.breakpoints.down('md')]: {
            ...theme.palette.common.ellipsis(2),
          },
        },

        '& .MuiButton-label': {
          zIndex: 6,
          '& div': {
            fontSize: '22px',
            lineHeight: '32px',

            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
      },
      '& .linkBtn': {
        position: 'relative',
        zIndex: 8,
      },

      '& .slick-slide': {
        flex: '1',
        display: 'flex',
        float: 'none',
        height: 'auto',
        '& > div > div': {
          height: '100%',
        },
        '& .imageWrap': {
          // height: '100%',
          maxHeight: pxToRem(300),
          [theme.breakpoints.down('sm')]: {
            maxHeight: pxToRem(191),
          },
        },
        '& img': {
          [theme.breakpoints.up('lg')]: {
            maxHeight: pxToRem(300),
          },
        },
        '& .img-to-animate': {
          '& img': {
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'none',
            },
          },
        },
      },

      '& .heartIcon': {
        '& svg': {
          width: '24px',
          height: '24px',
        },
      },

      // Slick Slider
      '& .slick-list': {
        zIndex: 2,
      },

      '& .slick-slide:hover': {
        boxShadow: 'none',
        background: 'transparent',
      },

      [theme.breakpoints.up('lg')]: {
        '& .slick-list': {
          zIndex: 2,
          overflow: 'visible',
        },

        '& .slick-track': {
          paddingBottom: '0',
        },

        '& .slick-disabled': {
          display: 'none !important',
        },

        '& .slick-slider': {
          paddingLeft: '5.8%',
          paddingRight: '5.8%',
        },

        '& .slick-slide': {
          ...theme.mixins.marginRight(32),
        },

        '& .slick-next': {
          left: 'auto',
          right: '8px',
          top: '41%',
          transform: 'translateY(-50%)',
        },
        '& .slick-prev': {
          left: '8px',
          right: 'auto',
          top: '41%',
          transform: 'translateY(-50%)',
        },

        '& .slick-next > button, & .slick-prev  > button': {
          border: 'none',
          width: '56px',
          height: '56px',

          '&:hover': {
            backgroundColor: (props) => !isEmpty(props.fields?.arrowColor?.fields?.colorCode?.value) ? (props.fields?.arrowColor?.fields?.colorCode?.value) : '#000',
          },
        },

      },

      [theme.breakpoints.down('md')]: {
        '& .slick-slide': {
          ...theme.mixins.marginRight(24),
        },
      },

    },



    '& .eventImageWrapper': {
      position: 'relative',
      overflow: 'hidden',
      minHeight: '190px',
      [theme.breakpoints.up('md')]: {
        minHeight: '416px',
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: '200px',
      },
      '& picture': {
        paddingTop: '0',
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transition: 'all 1s ease',
      },
    },


    '& .eventBgImage': {
      position: 'absolute',
      width: 'auto',
      height: '100%',
      top: '79.29px',
      ...theme.mixins.right('-203px'),
      zIndex: 1,
      '& img': {
        width: 'auto',

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },

      [theme.breakpoints.down('md')]: {
        height: '113%',
        bottom: 'unset',
        top: '25px',
        ...theme.mixins.left('-28px'),
        ...theme.mixins.right(0),
      },
    },



  },
}));

export default useStyles;

