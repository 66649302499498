import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';

import EventsCarousel from './EventCarousel';
import { getEventListStart } from '../CollageType1/redux/actions';
import { selectEventListData } from '../CollageType1/redux/selectors';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';

export const mapDispatchToProps = dispatch => {
  return {
    getEventListStart: (language, isEditor) =>
      dispatch(getEventListStart(language, isEditor)),
  };
};

export const mapStateToProps = createStructuredSelector({
  eventListData: selectEventListData,
});

export default withErrorBoundary(
  withSitecoreContext()(
    withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(EventsCarousel))
  )
);
