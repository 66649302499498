/* istanbul ignore file */
import { getEventTags } from '../../../utils/getEventTags';

import get from 'lodash/get';
export const getProcessedProps = (eventItems, featuredText) => {
  return {
    fields: {
      isCollageType1: true,
      collageType1Items: getCarouselType1Items(eventItems, featuredText),
    },
  };
};

const getCarouselType1Items = (eventItems, featuredText) => {
  const carouselItems = [];
  if (eventItems && eventItems.length > 3) {
    eventItems = eventItems.slice(0, 5);
  }
  eventItems &&
    eventItems.forEach(item => {
      carouselItems.push({
        fields: {
          primaryCTA: item.itemUrl,
          title: item.name,
          attributeLabelCTA: getEventTags(item),
          backgroundColor: {
            value: 'white',
          },
          desktopImage: {
            value: {
              src: item.thumbnailImage,
              alt: item.title,
              width: '128',
              height: '128',
              '3x2-card': {
                'large': get(item, 'thumbnailImage', ''),
              },
            },
          },
          mobileImage: {
            value: {
              src: item.thumbnailImage,
              alt: item.title,
              width: '128',
              height: '128',
              '3x2-card': {
                'large': get(item, 'thumbnailImage', ''),
              },
            },
          },
          startDate: item.startDate,
          endDate: item.endDate,
          openTime: item.openTime,
          closeTime: item.closeTime,
          umbrellaEventCount: item.umbrellaEventCount,
          itemUniqueKey: item.itemUniqueKey,
          locationTitle: {
            value:
              item.location_Titles &&
              item.location_Titles.length > 0 &&
              item.location_Titles[0],
          },
          region: {
            value:
              item.location_Regions &&
              item.location_Regions.length > 0 &&
              item.location_Regions[0],
          },
          urlSlug: item.urlSlug,
          eventType: {
            featured: item.isFeatured ? featuredText : false,
          },
          eventCategories: item.eventCategories,
        },
      });
    });
  return carouselItems;
};
